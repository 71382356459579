import React from 'react';

const ReactLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.67 161.61">
    <circle cx="90.84" cy="80.65" r="16.22" />
    <path d="M90.84,117.83a241,241,0,0,1-31-1.88A138.77,138.77,0,0,1,33.22,110c-9.68-3.3-17.88-7.7-23.73-12.7C3.28,92,0,86.25,0,80.65s3.12-11,9-16c5.47-4.68,13.25-8.85,22.5-12.08a151.73,151.73,0,0,1,27.22-6.44,219,219,0,0,1,32.1-2.31c21.65,0,42.44,3,58.53,8.59,9.41,3.24,17.35,7.43,23,12.11,6.11,5.1,9.34,10.69,9.34,16.17,0,5.69-3.41,11.51-9.87,16.82-6,5-14.48,9.32-24.5,12.64a145.93,145.93,0,0,1-26.17,5.78A227.39,227.39,0,0,1,90.84,117.83Zm0-66.28a210.46,210.46,0,0,0-31,2.22,142.67,142.67,0,0,0-25.81,6.09c-8.34,2.91-15.26,6.59-20,10.65C10,74,7.76,77.59,7.76,80.65s2.41,7,6.78,10.79C19.65,95.81,27,99.71,35.73,102.7a131.12,131.12,0,0,0,25.14,5.55,232.58,232.58,0,0,0,30,1.82c20.56,0,39.75-2.6,54-7.33,9.12-3,16.73-6.91,22-11.26,4.54-3.74,7-7.58,7-10.83,0-3.06-2.33-6.69-6.55-10.21-4.9-4.09-12-7.8-20.51-10.73C131.55,54.45,111.66,51.55,90.84,51.55Z" />
    <path d="M54,161.56a17.12,17.12,0,0,1-8.67-2.13c-4.72-2.72-8-8.19-9.39-15.82-1.32-7.07-1-15.9.78-25.53a150.4,150.4,0,0,1,8-26.81,219,219,0,0,1,14-28.95h0C69.58,43.56,82.61,27.07,95.45,15.9c7.5-6.53,15.1-11.31,22-13.84,7.47-2.74,13.93-2.75,18.67,0,4.93,2.84,8.27,8.7,9.65,17,1.28,7.68.83,17.21-1.3,27.55a146.68,146.68,0,0,1-8.06,25.55,227.8,227.8,0,0,1-13.46,27.22,239.6,239.6,0,0,1-17.11,25.89,139.26,139.26,0,0,1-18.42,20.13c-7.7,6.73-15.6,11.65-22.86,14.21A32,32,0,0,1,54,161.56ZM127.78,7.76a22.84,22.84,0,0,0-7.69,1.59c-6,2.2-12.75,6.49-19.55,12.41-12.2,10.62-24.65,26.4-35.05,44.43h0A211.69,211.69,0,0,0,51.94,94.12a143.38,143.38,0,0,0-7.61,25.41c-1.65,8.69-1.91,16.52-.77,22.65,1,5.26,3,9,5.64,10.53s7.31,1.44,12.74-.48c6.34-2.24,13.37-6.64,20.33-12.73a131.39,131.39,0,0,0,17.37-19,230.48,230.48,0,0,0,16.55-25c10.27-17.81,17.6-35.73,20.64-50.46,1.94-9.41,2.37-17.95,1.24-24.7-1-5.8-3-9.89-5.87-11.51A8.76,8.76,0,0,0,127.78,7.76Z" />
    <path d="M127.72,161.61a29.94,29.94,0,0,1-9.91-1.88c-6.79-2.38-14.3-7-21.73-13.41A151.38,151.38,0,0,1,76.87,126,218.61,218.61,0,0,1,58.79,99.36h0C48,80.62,40.17,61.1,36.9,44.4c-1.91-9.76-2.26-18.73-1-25.94C37.23,10.62,40.45,5,45.19,2.28,50.12-.57,56.86-.53,64.7,2.39,72,5.11,80,10.26,87.91,17.27A145.27,145.27,0,0,1,106,37a225.52,225.52,0,0,1,16.86,25.24A239.47,239.47,0,0,1,136.77,90,139.15,139.15,0,0,1,145,116c2,10,2.29,19.33.89,26.9-1.49,8-4.84,13.77-9.69,16.58A16.78,16.78,0,0,1,127.72,161.61ZM65.51,95.47a210.38,210.38,0,0,0,17.43,25.68,143,143,0,0,0,18.21,19.28c6.7,5.77,13.35,9.91,19.24,12,5,1.78,9.28,1.91,11.93.38s4.9-5.62,6-11.28c1.23-6.61.92-14.9-.87-24A131.17,131.17,0,0,0,129.6,93a232.47,232.47,0,0,0-13.43-26.85C105.86,48.35,94,33.05,82.76,23.07,75.57,16.69,68.39,12.05,62,9.66,56.48,7.6,51.89,7.37,49.08,9c-2.65,1.53-4.62,5.36-5.55,10.78-1.08,6.29-.74,14.28,1,23.13,3.11,15.88,10.56,34.54,21,52.56Z" />
  </svg>
);

export default ReactLogo;
