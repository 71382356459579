import React from 'react';

const Monitor = () => (


  <svg viewBox="0 0 494 321" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-802.000000, -1370.000000)">
        <g id="Services" transform="translate(0.000000, 1024.000000)">
          <g id="Group-6" transform="translate(802.000000, 346.000000)">
            <g id="Group-5" transform="translate(79.000000, 0.000000)">
              <path d="M259.861239,307.836257 L256.115858,307.836257 C256.115858,307.836257 234.118985,301.968655 234.118985,240.437895 C234.118985,222.312982 136.980061,222.324211 136.980061,241.787135 C136.980061,301.031111 114.41064,307.836257 114.41064,307.836257 L111.371371,307.836257 C108.660873,307.836257 106.477157,309.958363 106.477157,312.673684 L106.477157,315.131696 C106.477157,317.847018 108.660873,320 111.371371,320 L259.861239,320 C262.571736,320 265.258883,317.847018 265.258883,315.131696 L265.258883,312.673684 C265.258883,309.958363 262.571736,307.836257 259.861239,307.836257" id="Fill-36" fill="#FFFFFF" />
              <path d="M12.699736,0 L357.915492,0 C364.568447,0 368,3.49754386 368,10.1595322 L368,251.438596 C368,258.102456 364.568447,266.666667 357.915492,266.666667 L12.699736,266.666667 C6.04771574,266.666667 0,258.102456 0,251.438596 L0,10.1595322 C0,3.49754386 6.04771574,0 12.699736,0 Z M17.7461929,210.526316 L350.253807,210.526316 L350.253807,19.6491228 L17.7461929,19.6491228 L17.7461929,210.526316 Z" id="Combined-Shape" fill="#FFFFFF" />
              <path d="M200.379736,239.281404 C200.379736,247.624795 193.628711,254.384094 185.303878,254.384094 C176.981848,254.384094 170.23269,247.624795 170.23269,239.281404 C170.23269,230.940819 176.981848,224.18152 185.303878,224.18152 C193.628711,224.18152 200.379736,230.940819 200.379736,239.281404" id="Fill-39" fill="#000000" />
            </g>
            <g id="Group" transform="translate(0.000000, 109.000000)">
              <path d="M159,198.428264 C159,205.591436 153.409895,212 146.28186,212 L13.3318246,212 C6.20378947,212 0,205.591436 0,198.428264 L0,13.5717357 C0,6.40856388 6.20378947,0 13.3318246,0 L146.28186,0 C153.409895,0 159,6.40856388 159,13.5717357 L159,198.428264" id="Fill-84" fill="#FFFFFF" />
              <polygon id="Fill-85" fill="#000000" points="13.0175439 178.378855 145.982456 178.378855 145.982456 15.876652 13.0175439 15.876652" />
              <path d="M90.4747193,194.742079 C90.4747193,200.663137 85.7019298,205.455084 79.8068421,205.455084 C73.9126842,205.455084 69.1389649,200.663137 69.1389649,194.742079 C69.1389649,188.821956 73.9126842,184.024405 79.8068421,184.024405 C85.7019298,184.024405 90.4747193,188.821956 90.4747193,194.742079" id="Fill-86" fill="#000000" />
            </g>
            <g id="Group-4" transform="translate(401.000000, 147.000000)">
              <path d="M93,161.509995 C93,167.5678 87.1231515,173 81.0302424,173 L11.1928788,173 C5.10278788,173 0,167.5678 0,161.509995 L0,11.4918757 C0,5.4322 5.10278788,0 11.1928788,0 L81.0302424,0 C87.1231515,0 93,5.4322 93,11.4918757 L93,161.509995" id="Fill-101" fill="#434544" />
              <path d="M93,161.509995 C93,167.5678 87.1231515,173 81.0302424,173 L11.1928788,173 C5.10278788,173 0,167.5678 0,161.509995 L0,11.4918757 C0,5.4322 5.10278788,0 11.1928788,0 L81.0302424,0 C87.1231515,0 93,5.4322 93,11.4918757 L93,161.509995" id="Fill-102" fill="#FFFFFF" />
              <polygon id="Fill-103" fill="#000000" points="6.57575758 147.751351 86.4242424 147.751351 86.4242424 21.5081081 6.57575758 21.5081081" />
              <path d="M52.7892424,160.109162 C52.7892424,163.776762 49.8010303,166.755168 46.1129697,166.755168 C42.4239697,166.755168 39.4357576,163.776762 39.4357576,160.109162 C39.4357576,156.439692 42.4239697,153.462222 46.1129697,153.462222 C49.8010303,153.462222 52.7892424,156.439692 52.7892424,160.109162" id="Fill-104" fill="#000000" />
              <path d="M62,13.2237459 C62,14.1841297 61.2184242,14.9621622 60.2546061,14.9621622 L27.1090303,14.9621622 C26.1452121,14.9621622 25.3636364,14.1841297 25.3636364,13.2237459 L25.3636364,12.9600378 C25.3636364,11.9996541 26.1452121,11.2216216 27.1090303,11.2216216 L60.2546061,11.2216216 C61.2184242,11.2216216 62,11.9996541 62,12.9600378 L62,13.2237459" id="Fill-105" fill="#000000" />
            </g>
            <g id="Cube" transform="translate(211.000000, 50.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M53.7148853,0.516594366 C53.2067051,0.182064655 52.6104277,0.00244315936 52,0 C51.391455,0 50.7966408,0.178859359 50.2929096,0.516594366 L1.37190826,32.7687067 C0.517226868,33.3401256 0.00360725391,34.2927041 0,35.3131 L0,83.6951236 C0.00360725391,84.7155194 0.517226868,85.6680979 1.37190826,86.2395168 L50.2851147,118.491629 C51.3256837,119.169457 52.6743163,119.169457 53.7148853,118.491629 L104,85.3374138 L104,35.3131 C103.996393,34.2927041 103.482773,33.3401256 102.628092,32.7687067 L53.7148853,0.516594366 Z M48.9132064,110.233916 L6.17358717,82.0451231 L6.17358717,41.0187091 L48.9132064,69.1997922 L48.9132064,110.233916 Z M52.0311797,63.8874074 L8.64458102,35.3131 L52,6.7233719 L95.355419,35.3131 L52.0311797,63.8874074 Z M97.8575926,82.0451231 L55.0867936,110.233916 L55.0867936,69.1997922 L97.8264128,41.0187091 L97.8575926,82.0451231 Z" id="Shape" />
            </g>
            <g id="Cube" transform="translate(51.000000, 169.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M28.9233998,0.277832264 C28.6497643,0.0979171255 28.3286919,0.00131396806 28,0 C27.6723219,0 27.3520374,0.0961932687 27.0807975,0.277832264 L0.738719832,17.6235061 C0.278506775,17.9308239 0.00194236749,18.443135 0,18.9919193 L0,45.0125034 C0.00194236749,45.5612878 0.278506775,46.0735989 0.738719832,46.3809166 L27.0766002,63.7265905 C27.6369066,64.0911365 28.3630934,64.0911365 28.9233998,63.7265905 L56,45.8957519 L56,18.9919193 C55.9980576,18.443135 55.7214932,17.9308239 55.2612802,17.6235061 L28.9233998,0.277832264 Z M26.3378804,59.2854677 L3.32423924,44.1251082 L3.32423924,22.0604822 L26.3378804,37.216695 L26.3378804,59.2854677 Z M28.0167891,34.3596141 L4.6547744,18.9919193 L28,3.6159311 L51.3452256,18.9919193 L28.0167891,34.3596141 Z M52.6925498,44.1251082 L29.6621196,59.2854677 L29.6621196,37.216695 L52.6757608,22.0604822 L52.6925498,44.1251082 Z" id="Shape" />
            </g>
            <g id="Cube" transform="translate(429.000000, 212.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M19.1101034,0.182327423 C18.9293085,0.0642581136 18.7171714,0.000862291538 18.5,0 C18.2834984,0 18.0718818,0.0631268326 17.8926698,0.182327423 L0.488082746,11.5654259 C0.184013405,11.7671032 0.00128334995,12.1033073 0,12.463447 L0,29.5394554 C0.00128334995,29.8995951 0.184013405,30.2357993 0.488082746,30.4374765 L17.8898966,41.820575 C18.260099,42.0598083 18.739901,42.0598083 19.1101034,41.820575 L37,30.1190872 L37,12.463447 C36.9987167,12.1033073 36.8159866,11.7671032 36.5119173,11.5654259 L19.1101034,0.182327423 Z M17.4018138,38.9060882 L2.19637236,28.9571023 L2.19637236,14.4771915 L17.4018138,24.4234561 L17.4018138,38.9060882 Z M18.5110928,22.5484967 L3.07547594,12.463447 L18.5,2.37295479 L33.9245241,12.463447 L18.5110928,22.5484967 Z M34.8147204,28.9571023 L19.5981862,38.9060882 L19.5981862,24.4234561 L34.8036276,14.4771915 L34.8147204,28.9571023 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Monitor;
