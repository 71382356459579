import React from 'react';
import Intro from './sections/0_intro/Intro';
import Services from './sections/1_services/Services';
import Technologies from './sections/2_technologies/Technologies';
import Design from './sections/3_design/Design';

const Index = () => (
  <>
    <Intro />
    <Services />
    <Technologies />
    <Design />
  </>
);

export default Index;
