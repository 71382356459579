import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import SectionStyled from '../../../shared/SectionStyled';
import MainText from '../../../shared/MainText';
import Modernware from '../../../../assets/svg/Modernware';
import AutoCol from '../../../shared/AutoCol';
import ButtonStyled from '../../../shared/ButtonStyled';
import IntroLocales from './IntroLocales.json';
import { setIsEstimateMenuOpen } from '../../../../redux/actions';

const Intro = () => {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.lang);
  const locales = IntroLocales[lang];

  const onButtonClick = () => {
    dispatch(setIsEstimateMenuOpen(true));
  };

  return (
    <SectionStyled
      id="intro"
      background="white"
    >
      <Grid>
        <Row center="xs" start="md">
          <AutoCol xs={5} md={4}>
            <Modernware />
          </AutoCol>
          <Col md={6} xs={11}>
            <MainText color="black">
              {locales.main}
            </MainText>
            <ButtonStyled onClick={onButtonClick}>
              {locales.estimateForFree}
            </ButtonStyled>
          </Col>
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Intro;
