import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import MainText from '../../../shared/MainText';
import Layers from '../../../../assets/svg/Layers';
import AutoCol from '../../../shared/AutoCol';
import SectionStyled from '../../../shared/SectionStyled';
import ButtonStyled from '../../../shared/ButtonStyled';
import DesignLocales from './DesignLocales.json';

const openInNewTab = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const Design = () => {
  const lang = useSelector(state => state.lang);
  const locales = DesignLocales[lang];

  return (
    <SectionStyled
      id="design"
      background="black"
    >
      <Grid>
        <Row center="xs" start="md">
          <AutoCol md={6} xs={8}>
            <MainText color="white">
              {locales.main}
            </MainText>
            <MainText color="white">
              {locales.text2}
            </MainText>
            <ButtonStyled onClick={() => openInNewTab('https://www.behance.net/Modernware')} color="white">
              {locales.seeOurProjects}
            </ButtonStyled>
          </AutoCol>
          <AutoCol md={3} xs={5}>
            <Layers />
          </AutoCol>
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Design;
