import React from 'react';

const Git = () => (
  <svg viewBox="0 0 133 133" version="1.1">
    <defs>
      <polygon id="path-1" points="3.29640532e-15 0 133 0 133 133 3.29640532e-15 133" />
    </defs>
    <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-142.000000, -2676.000000)">
        <g id="Group-18" transform="translate(142.000000, 2311.000000)">
          <g id="Group-7">
            <g id="Git-Icon-Black" transform="translate(0.000000, 365.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path d="M130.491283,60.5769194 L72.4227869,2.50876487 C69.0792781,-0.836254955 63.6558465,-0.836254955 60.309443,2.50876487 L48.2511005,14.5687844 L63.547291,29.8652662 C67.102121,28.663896 71.1780174,29.4701167 74.0105916,32.302745 C76.8605348,35.1541898 77.6595031,39.2649022 76.4277603,42.8328268 L91.1710415,57.5749414 C94.7388982,56.3446226 98.8509797,57.1392638 101.700923,59.9936035 C105.68129,63.9725994 105.68129,70.4238125 101.700923,74.4057032 C97.7176605,78.3890414 91.2680178,78.3890414 87.283308,74.4057032 C84.2900717,71.409515 83.5489996,67.0093158 85.0673289,63.3212542 L71.3169684,49.5720792 L71.3169684,85.7521378 C72.2867307,86.2326859 73.2029389,86.8738991 74.0105916,87.6801198 C77.9909592,91.6605631 77.9909592,98.1103287 74.0105916,102.096562 C70.0302241,106.075558 63.5762391,106.075558 59.5987664,102.096562 C55.6183989,98.1103287 55.6183989,91.6605631 59.5987664,87.6801198 C60.5830028,86.6973122 61.7221116,85.9533311 62.9379329,85.4554139 L62.9379329,48.9395506 C61.7221116,48.4430807 60.5858976,47.7048894 59.5987664,46.7162921 C56.583819,43.7027346 55.8586684,39.2764816 57.4059458,35.5739456 L42.3268663,20.4916841 L2.50871709,60.3076967 C-0.836239029,63.6556114 -0.836239029,69.0791463 2.50871709,72.4241662 L60.580108,130.492321 C63.9236167,133.835893 69.3456009,133.835893 72.6934518,130.492321 L130.491283,72.6933889 C133.836239,69.3483691 133.836239,63.9204918 130.491283,60.5769194" id="Fill-1" fill="#0A0B09" mask="url(#mask-2)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Git;
