import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import MainText from '../../../shared/MainText';
import Aws from '../../../../assets/svg/Aws';
import ReactLogo from '../../../../assets/svg/ReactLogo';
import Docker from '../../../../assets/svg/Docker';
import AutoCol from '../../../shared/AutoCol';
import SectionStyled from '../../../shared/SectionStyled';
import Mobx from '../../../../assets/svg/Mobx';
import Node from '../../../../assets/svg/Node';
import Git from '../../../../assets/svg/Git';
import TechnologiesLocales from './TechnologiesLocales.json';

const Technologies = () => {
  const lang = useSelector(state => state.lang);
  const locales = TechnologiesLocales[lang];

  return (
    <SectionStyled
      id="technologies"
      background="white"
    >
      <Grid>
        <Row>
          <AutoCol md={4}>
            <Row style={{ marginBottom: '3em' }}>
              <AutoCol md={4} xs={3}>
                <Mobx />
              </AutoCol>
              <AutoCol md={4} xs={3}>
                <ReactLogo />
              </AutoCol>
            </Row>
            <Row style={{ marginBottom: '3em' }}>
              <AutoCol md={4} xs={3}>
                <Node />
              </AutoCol>
              <AutoCol md={4} xs={3}>
                <Docker />
              </AutoCol>
            </Row>
            <Row>
              <AutoCol md={4} xs={3}>
                <Git />
              </AutoCol>
              <AutoCol md={4} xs={3}>
                <Aws />
              </AutoCol>
            </Row>
          </AutoCol>
          <AutoCol md={6}>
            <MainText color="black">
              {locales.main}
            </MainText>
          </AutoCol>
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Technologies;
