import React from 'react';

const Layers = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269 260.91">
    <circle fill="#FFF" cx="220.38" cy="123.11" r="4.9" />
    <circle fill="#FFF" cx="44.16" cy="123.11" r="4.9" />
    <circle fill="#FFF" cx="58.84" cy="137.79" r="4.9" />
    <circle fill="#FFF" cx="88.21" cy="137.79" r="4.9" />
    <circle fill="#FFF" cx="73.53" cy="152.48" r="4.89" />
    <circle fill="#FFF" cx="102.9" cy="152.48" r="4.89" />
    <circle fill="#FFF" cx="117.58" cy="167.16" r="4.9" />
    <circle fill="#FFF" cx="146.95" cy="167.16" r="4.9" />
    <circle fill="#FFF" cx="161.64" cy="152.48" r="4.89" />
    <circle fill="#FFF" cx="132.27" cy="181.85" r="4.9" />
    <circle fill="#FFF" cx="176.32" cy="137.79" r="4.9" />
    <circle fill="#FFF" cx="205.69" cy="137.79" r="4.9" />
    <circle fill="#FFF" cx="191.01" cy="152.48" r="4.89" />
    <path
      fill="#FFF"
      d="M223,156.49l46-26.27-47.27-26.84,47.27-27L134.5,0,0,76.38l47.27,27L0,130.22l47.27,27L0,184.07l134.5,76.84L269,184.07l-47.27-26.84.35-.2A4.8,4.8,0,0,0,223,156.49ZM134.5,11.25,249.22,76.4,211.84,97.76h0L134.5,142,19.78,76.4Zm-80,99.29a4.82,4.82,0,0,0,7.75,1.39l11.07,6.33a5,5,0,1,0,4.48,2.55l50.56,28.89a4.77,4.77,0,0,0-1,2.78,4.9,4.9,0,0,0,9.79,0,4.13,4.13,0,0,0-.14-.7l49.21-28.11A4.82,4.82,0,0,0,191,128a4.89,4.89,0,0,0,4.89-4.9,4.78,4.78,0,0,0-2-3.83l10.77-6.16a4.33,4.33,0,0,0,1,.2,4.82,4.82,0,0,0,4.59-3.39l1.57-.9,37.37,21.22-9.71,5.55a4.88,4.88,0,0,0-9.34,2,4.76,4.76,0,0,0,1,2.78l-19.31,11h0l-31.27,17.87a4.8,4.8,0,0,0,.65-2.31,4.9,4.9,0,1,0-5.1,4.85L165,178.34a4.88,4.88,0,0,0-8.29,3.5A4.55,4.55,0,0,0,157,183L134.5,195.79l-27-15.43a4.84,4.84,0,0,0-4.6-3.41,4.43,4.43,0,0,0-1,.21L91.12,171a4.79,4.79,0,0,0,2-3.84,4.89,4.89,0,0,0-4.89-4.89,4.83,4.83,0,0,0-4.78,4.34L34.22,138.5a4.25,4.25,0,0,0,.14-.71,4.89,4.89,0,0,0-4.89-4.89,4.76,4.76,0,0,0-3,1.15l-6.65-3.8Zm194.73,73.55L134.5,249.64,19.78,184.09l37.36-21.22,77.36,44.19,77.35-44.19Z"
    />
  </svg>
);

export default Layers;
