import React from 'react';

const Mobx = () => (
  <svg viewBox="0 0 120 120" version="1.1">
    <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-148.000000, -2311.000000)" fillRule="nonzero">
        <g id="Group-18" transform="translate(142.000000, 2311.000000)">
          <g id="Group-7">
            <g id="mobx-seeklogo.com" transform="translate(6.000000, 0.000000)">
              <path d="M120,110.845377 L120,9.62291513 C120,5.47044769 117.202192,1.96591883 113.369142,0.845070423 L109.016232,3.41959076 L60.7092143,54.3313113 L59.1947414,59.0104465 L58.3098592,63.5017457 L73.373651,119.149521 L75.7043845,120 L110.738489,120 C115.853434,120 120,115.901448 120,110.845377" id="Path" fill="#000000" />
              <path d="M0,9.18488301 L0,110.741928 C0,113.882555 1.58508555,116.653566 4.0016156,118.309859 L6.96229137,116.899289 L57.9273677,62.6072718 L59.9253853,58.735661 L60,54.9928607 L46.1293058,2.48515332 L44.0289319,0 L9.23118622,0 C4.13298861,0 0,4.11225772 0,9.18488301" id="Path" fill="#000000" />
              <path d="M59.7040542,58.3098592 L113.239437,0.375440783 C112.4151,0.132443666 111.543185,0 110.639814,0 L43.943662,0 L59.7040542,58.3098592 Z" id="Path" fill="#000000" />
              <path d="M9.41530183,120 L76.056338,120 L59.725267,58.3098592 L4.22535211,118.369867 C5.70080351,119.397236 7.48811705,120 9.41530183,120" id="Path" fill="#000000" />
              <path d="M44.8764802,46.0472173 L51.5447216,46.0472173 C54.2014463,56.0406401 57.2253223,64.8098263 60.5988882,72.7220308 C64.4202422,63.8200888 67.2212559,55.0450481 69.5922599,46.0472173 L75.8931916,46.0472173 C73.0712252,57.2273756 68.4550925,68.4951944 63.1749767,80.1256797 L57.6015211,80.1256797 C52.5296639,69.1042272 48.2117916,57.7726414 44.8764802,46.0472173 Z M22.8822997,28.7323944 L37.7094501,28.7323944 L37.7094501,92.1126289 L22.8169014,92.1126289 L22.8169014,87.2269167 L31.9440856,87.2269167 L31.9440856,33.4878819 L22.8822997,33.4878819 L22.8822997,28.7323944 Z M97.9629298,33.4879294 L88.900985,33.4879294 L88.900985,87.2269644 L98.028169,87.2269644 L98.028169,92.1126761 L83.1357793,92.1126761 L83.1357793,28.7324418 L97.9629298,28.7324418 L97.9629298,33.4879294 Z" id="Shape" fill="#FFFFFF" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Mobx;
