import React from 'react';
import { Col, Row, Grid } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import MainText from '../../../shared/MainText';
import Monitor from '../../../../assets/svg/Monitor';
import AutoCol from '../../../shared/AutoCol';
import SectionStyled from '../../../shared/SectionStyled';
import ButtonStyled from '../../../shared/ButtonStyled';
import ServicesLocales from './ServicesLocales.json';
import { setHeaderColor, setIsOfferOpen } from '../../../../redux/actions';

const Services = () => {
  const lang = useSelector(state => state.lang);
  const dispatch = useDispatch();
  const locales = ServicesLocales[lang];

  const onClick = () => {
    navigate('/offer');
    window.scrollTo(0, 0);
    dispatch(setHeaderColor('black'));
    dispatch(setIsOfferOpen(true));
  };

  return (
    <SectionStyled
      id="services"
      background="black"
    >
      <Grid>
        <Row center="xs" start="md">
          <Col md={6} xs={10}>
            <MainText color="white">
              {locales.main}
            </MainText>
            <MainText color="white">
              {locales.main2}
            </MainText>
            <ButtonStyled onClick={onClick} color="white">{locales.button}</ButtonStyled>
          </Col>
          <AutoCol md={5} xs={8}>
            <Monitor />
          </AutoCol>
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Services;
