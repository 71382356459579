import React from 'react';

const Docker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.68 143.59">
    <path d="M197.1,62c-6.72-4.13-16-4.65-23.76-2.58-1-8.27-6.2-15-12.91-20.15l-2.59-2.06-2.06,2.58c-4.14,4.65-5.68,12.91-5.17,19.11a27.82,27.82,0,0,0,4.65,12.91c-2.07,1-4.65,2.07-6.71,3.1a43.52,43.52,0,0,1-14,2.07H.83l-.52,3.1A58.17,58.17,0,0,0,5,109.5L7,113.11v.52c12.4,20.66,34.61,30,58.89,30,46.48,0,84.7-20.15,102.78-63.53,11.88.51,23.76-2.58,29.44-14l1.55-2.58Zm-157,53.72c-5.68,0-10.85-4.65-10.85-10.33S33.88,95,40.08,95s10.85,4.64,10.85,10.33S45.76,115.7,40.08,115.7Z" />
    <rect x="86.57" y="51.13" width="20.14" height="20.14" />
    <rect x="86.57" y="25.83" width="20.14" height="20.14" />
    <rect x="86.57" width="20.14" height="20.14" />
    <rect x="111.36" y="51.13" width="20.14" height="20.14" />
    <polygon points="56.61 51.13 36.47 51.13 36.47 71.28 56.61 71.28 56.61 51.13 56.61 51.13" />
    <rect x="61.77" y="51.13" width="20.14" height="20.14" />
    <polygon points="31.82 51.13 11.67 51.13 11.67 71.28 31.82 71.28 31.82 51.13 31.82 51.13" />
    <rect x="61.77" y="25.83" width="20.14" height="20.14" />
    <polygon points="56.61 25.82 36.47 25.82 36.47 45.97 56.61 45.97 56.61 25.82 56.61 25.82" />
  </svg>
);

export default Docker;
